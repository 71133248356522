@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap");

* {
	box-sizing: border-box;
}
body {
	padding: 0;
	margin: 0;
}
.outer {
	margin: 0 auto;
	font-family: "Rubik", sans-serif;
	font-size: 16px;
	line-height: 20px;
	color: #575a89;
}
.flex-50 {
	flex: 1 1 50%;
	@media (max-width: 768px) {
		flex: 1 1 100%;
	}
}
svg {
	max-width: 100%;
	flex-shrink: 0;
}
.container-block {
	max-width: 1200px;
	margin: 0 auto;
	&.section {
		padding: 80px 0;
		@media (max-width: 768px) {
			padding: 30px 0;
		}
	}
	&.pad-btm {
		padding-bottom: 80px;
		@media (max-width: 768px) {
			padding-bottom: 30px;
		}
	}
}
@media (max-width: 768px) {
	#top-menu {
		position: sticky;
		top: 0;
		background-color: #fff;
		z-index: 10;
	}
}
.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
	margin-bottom: 50px;
	font-family: "Roboto", sans-serif;
	padding: 16px;
	@media (max-width: 768px) {
		height: auto;
		border-bottom: 3px solid rgba(92, 218, 149, 0.3);
		margin-bottom: 30px;
		svg {
			width: 120px;
			height: 40px;
		}
	}
	.navbar-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		display: flex;
		align-items: center;
		width: 100%;
		padding-left: 80px;
		@media (max-width: 768px) {
			flex-direction: column;
			padding-left: 0;
			align-items: flex-end;
			display: none;
		}
		.list-item {
			font-size: 18px;
			line-height: 24px;
			color: #05396b;
			margin-left: 30px;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			text-transform: capitalize;
			@media (max-width: 768px) {
				margin-left: 0;
			}
			&:first-child {
				margin-left: 0;
			}
			&:last-child {
				border: 1px solid #05396b;
				border-radius: 20px;
				padding: 7px 14px;
				margin-left: auto;
				&:hover {
					background-color: #05396b;
					color: #fff;
				}
			}
			&:hover {
				text-shadow: 2px 2px 4px #0c38635e;
			}
		}
	}
	.mobile-menu {
		display: none;
		@media (max-width: 768px) {
			display: block;
			.icon {
				span {
					display: block;
					width: 20px;
					height: 3px;
					background-color: #05396b;
					margin-top: 4px;
					border-radius: 3px;
					&:first-child {
						margin-top: 0;
					}
				}
			}
			&.active {
				+ .navbar-list {
					display: block;
					position: absolute;
					top: 70px;
					width: 100%;
					background-color: #fff;
					left: 0;
					padding: 0 20px 20px;
					text-align: right;
					box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.3);
					.list-item {
						margin-bottom: 12px;
					}
				}
			}
		}
	}
}

.section-wrap {
	display: flex;
	&.front-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 768px) {
			flex-wrap: wrap;
			padding: 0 16px;
		}
		.front-heading {
			font-weight: 500;
			font-size: 50px;
			line-height: 65px;
			color: #0c3863;
			width: 85%;
			@media (max-width: 768px) {
				width: 100%;
				font-size: 24px;
				line-height: 40px;
			}
		}
		.front-desc {
			font-weight: 400;
			font-size: 17px;
			line-height: 30px;
			color: #4f5665;
			margin-top: 20px;
			width: 80%;
			@media (max-width: 768px) {
				width: 100%;
				font-size: 16px;
			}
		}
		.get-started-button {
			width: 250px;
			background: #389583;
			border-radius: 10px;
			color: #fff;
			font-size: 16px;
			line-height: 20px;
			padding: 20px;
			border: 1px solid #389583;
			margin-top: 50px;
			font-weight: 700;
			@media (max-width: 768px) {
				margin-top: 27px;
				width: 200px;
				font-size: 14px;
				line-height: 24px;
				padding: 14px;
			}
		}
		.logopadding {
			padding-left: 20px;
			@media (max-width: 768px) {
				padding: 0;
				margin-top: 40px;
				max-height: 280px;
			}
		}
	}
	&.advantages-wrap {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@media (max-width: 768px) {
			flex-wrap: wrap;
			padding: 16px;
			.order2 {
				order: 2;
				&.imgheight {
					height: 300px;
					margin-top: -50px;
				}
			}
			svg {
				padding: 0;
				margin-top: 40px;
				max-height: 280px;
			}
		}
		&.height400 {
			max-height: 400px;
			@media (max-width: 768px) {
				max-height: unset;
			}
		}
		.advantage-heading {
			font-weight: 500;
			font-size: 35px;
			line-height: 50px;
			color: #0b132a;
			width: 80%;
			margin: 0 auto;
			margin-bottom: 40px;
			&.text-center {
				text-align: center;
			}
			@media (max-width: 768px) {
				width: 100%;
				font-size: 24px;
				line-height: 40px;
				margin-bottom: 27px;
				text-align: left !important;
			}
		}
		.advantage-desc {
			font-weight: 400;
			font-size: 16px;
			line-height: 30px;
			color: #4f5665;
			width: 80%;
			margin-top: 10px;
			margin: 0 auto;
			@media (max-width: 768px) {
				&.about-desc {
					font-size: 14px;
					line-height: 24px;
				}
			}
			&.text-green {
				color: #389583;
			}
			.advantage-list {
				list-style-type: none;
				padding: 0;
				margin: 0;
				color: #4f5665;
				li {
					font-size: 14px;
					line-height: 30px;
					margin-top: 15px;
					display: flex;
					align-items: flex-start;
					i {
						margin-right: 12px;
						font-size: 20px;
						margin-top: 5px;
					}
				}
			}
			@media (max-width: 768px) {
				width: 100%;
			}
		}
	}
	&.advantages-continued-wrap {
		display: flex;
		align-items: center;
		@media (max-width: 768px) {
			padding: 0 16px;
		}
		.big-font {
			font-weight: 500;
			font-size: 35px;
			line-height: 50px;
			color: #334669;
			text-align: center;
			@media (max-width: 768px) {
				font-size: 24px;
				line-height: 40px;
				text-align: left;
			}
		}
		.small-font {
			font-weight: 400;
			font-size: 20px;
			line-height: 30px;
			color: #389583;
			text-align: center;
			margin-top: 10px;
			@media (max-width: 768px) {
				font-size: 16px;
				line-height: 30px;
				text-align: left;
			}
		}
		.left-sec,
		.right-sec {
			flex: 1 1 50%;
		}
		.right-sec {
			padding-left: 40px;
			svg {
				width: 100%;
			}
			@media (max-width: 768px) {
				display: none;
			}
		}
		.advantage-items {
			display: flex;
			flex-wrap: wrap;
			margin-top: 40px;
			.item {
				display: flex;
				flex-direction: column;
				text-align: center;
				flex: 1 1 50%;
				margin-bottom: 20px;
				padding: 0 20px;
				@media (max-width: 768px) {
					flex-direction: row;
					width: 100%;
					padding: 0;
					flex: 1 1 100%;
				}
				.item-logo {
					width: 100px;
					height: 100px;
					margin: 0 auto;
					display: flex;
					justify-content: center;
					align-items: center;
					@media (max-width: 768px) {
						margin-right: 20px;
						justify-content: flex-start;
						width: 70px;
						height: 70px;
						flex-shrink: 0;
						margin: unset;
						margin-top: 10px;
						margin-right: 20px;
					}
				}
				&-heading {
					font-size: 24px;
					line-height: 30px;
					font-weight: 500;
					margin-top: 20px;
					margin-bottom: 15px;
					@media (max-width: 768px) {
						text-align: left;
						font-size: 16px;
						line-height: 30px;
						margin-top: 0;
						margin-bottom: 10px;
					}
				}
				&-desc {
					font-size: 18px;
					line-height: 30px;
					color: #389583;
					@media (max-width: 768px) {
						text-align: left;
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
		}
	}
	&.stats-box {
		.stats-column {
			display: flex;
			align-items: center;
			flex: 1 1 50%;
			justify-content: center;
			@media (max-width: 768px) {
				svg {
					width: 40px;
				}
			}
			@media (max-width: 768px) {
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
			}
			.stats {
				font-weight: 700;
				font-size: 25px;
				line-height: 30px;
				color: #05396b;
				margin-left: 24px;
				@media (max-width: 768px) {
					font-size: 22px;
					line-height: 30px;
				}
			}
			.stats-text {
				font-size: 20px;
				line-height: 30px;
				color: #389583;
				margin-left: 18px;
				@media (max-width: 768px) {
					text-align: center;
					font-size: 14px;
					line-height: 20px;
				}
			}
		}
	}
}

.schedule-wrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	padding-bottom: 40px;
	@media (max-width: 768px) {
		flex-direction: column;
		padding-left: 16px;
		padding-right: 16px;
		align-items: flex-start;
	}
	.heading {
		font-size: 35px;
		line-height: 45px;
		color: #0b132a;
		font-weight: 500;
		max-width: 300px;
		@media (max-width: 768px) {
			font-size: 24px;
			line-height: 40px;
		}
	}
	.desc {
		font-size: 16px;
		line-height: 30px;
		color: #05396b;
		font-weight: 400;
		margin-top: 10px;
	}
	.btn-wrap {
		@media (max-width: 768px) {
			margin-top: 30px;
			width: 100%;
		}
		.subscribe-button {
			width: 250px;
			background: #389583;
			border-radius: 30px;
			color: #fff;
			font-size: 16px;
			line-height: 25px;
			font-weight: 700;
			padding: 12px 20px;
			border: 1px solid #389583;
			cursor: pointer;
			transition: all 0.2s ease-in-out;
			&:hover {
				box-shadow: 4px 8px 8px 0 rgba(56, 149, 131, 0.46);
			}
		}
	}
}

.footer {
	background-color: #f8f8f8;
	padding: 60px;
	@media (max-width: 768px) {
		padding: 16px;
	}
	.container-block {
		display: flex;
		.footer-left {
			flex: 1 1 40%;
			margin-right: 100px;
			@media (max-width: 768px) {
				flex: 1 1 100%;
				margin-right: 0;
			}
			.footer-text {
				font-size: 16px;
				line-height: 30px;
				font-weight: 400;
				color: #4f5665;
				margin-top: 20px;
				width: 80%;
				@media (max-width: 768px) {
					font-size: 14px;
					width: 100%;
				}
			}
			.social-links {
				list-style-type: none;
				padding: 0;
				margin: 0;
				display: flex;
				margin-top: 20px;
				margin-bottom: 20px;
				li {
					margin-left: 10px;
					position: relative;
					line-height: 0;
					&:first-child {
						margin-left: 0;
					}
					img {
						width: 36px;
						position: relative;
						z-index: 1;
					}
					&::after {
						content: "";
						background-color: #389583;
						position: absolute;
						width: 90%;
						height: 90%;
						top: 2px;
						left: 2px;
						z-index: 0;
						line-height: 0;
						border-radius: 50%;
					}
				}
			}
			.copyright {
				color: #afb5c0;
				font-size: 16px;
				line-height: 30px;
			}
		}
		.footer-right {
			flex: 1 1 60%;
			display: flex;
			.footer-links-wrap {
				flex: 1 1 33%;
				.link-col-heading {
					font-size: 18px;
					line-height: 30px;
					font-weight: 500;
					margin-bottom: 20px;
					color: #0b132a;
				}
				.col-links {
					list-style-type: none;
					padding: 0;
					margin: 0;
					li {
						font-size: 16px;
						line-height: 30px;
						font-weight: 400;
						color: #4f5665;
						margin-top: 10px;
					}
				}
			}
			@media (max-width: 768px) {
				display: none;
			}
		}
	}
}
