@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: "Rubik";
  src: url(../fonts/Rubik-Regular.ttf);
}
button:active,
button:focus {
  outline: none;
}
button::-moz-focus-inner {
  border: 0;
}
.contentTable {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;

  .contentType {
    .contentTypeButtons {
      display: flex;
    }
    button:active,
    button:focus {
      outline: none;
    }
    button::-moz-focus-inner {
      border: 0;
    }
    h3 {
      font-family: "Rubik";
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;

      color: #05396b;
    }
    button {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      padding-top: 24px;
      width: 123px;
      height: 123px;
      background: none;
      border: none;
      background: #ffffff;
      box-shadow: 0px 4px 4px rgba(56, 149, 131, 0.25);
      border-radius: 16px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-right: 30px;
      letter-spacing: 0.25px;

      color: #379583;
    }

    .contentTypeButtonActive {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 24px;
      width: 123px;
      height: 123px;
      background: none;
      border: none;
      background: #5cdb94;
      box-shadow: inset 0px 4px 4px rgba(56, 149, 131, 0.5);
      border-radius: 16px;
      font-family: "Rubik";
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;

      letter-spacing: 0.25px;

      color: #ffffff;
    }
  }
  .tableHeader {
    margin-top: 100px;
    display: flex;
    list-style-type: none;
    justify-content: space-around;
    align-items: center;
    background: #ffffff;
    border-radius: 16px;
    height: 65px;
    box-shadow: 0px 2px 5px rgba(56, 149, 131, 0.5);

    li {
      font-family: "Rubik";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      text-align: center;

      color: #063869;
    }
  }
  .tableItem {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #ffffff;
    height: 128px;
    border: 1px solid #f0f0f0;
    box-shadow: 0px 10px 20px rgba(167, 209, 233, 0.45);
    border-radius: 100px;
    padding-right: 20px;
    img {
    }
    h4 {
      display: block;
      font-family: "Rubik";
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 163.42%;

      color: #858585;
    }
    .tableItemViews {
      display: flex;
      flex-direction: column;
      .tableItemViewsChild {
        display: flex;
        flex-direction: column;
        margin: 0;
      }
    }
    span {
      font-family: "Rubik";
      font-style: normal;
      font-weight: bold;
      font-size: 7px;

      color: #063869;
    }
    h6 {
      margin: 0;
      margin-top: 10px;
      margin-bottom: 10px;
      text-align: center;
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;

      text-align: center;
      width: 100;

      padding: 5px 10px 5px 10px;
      color: #ffffff;
      background: #5cdb94;
      border-radius: 215px;
    }
    ul {
      padding: 0;
      list-style-type: none;
      li {
        font-family: "Rubik";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 163.42%;
        /* or 16px */

        color: #05396b;
      }
    }
    h5 {
      font-family: Poppins;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 163.42%;
      color: #858585;
    }

    .optionButton {
      border: none;
      background: none;
      height: 10px;
      width: 10px;
      cursor: pointer;
    }
    .optionButtonPopUP {
      position: absolute;
      z-index: 3;
      width: 105px;
      border-radius: 10px;
      background: #ffffff;
      box-shadow: 1px 3px 11px 9px rgba(56, 149, 131, 0.25);
font-size: 12px;
      overflow: hidden;
      :last-child{
        border: none;
      
      }
      button {
        border: none;
        background: white;
        margin: 0;
        border-bottom: 1px solid grey;
        height: 35px;
       width: 105px;
      }
      button:hover {

        border: none;
        background: #5cdb94;
        margin: 0;
        border-bottom: 1px solid #1cd1a1;
        height: 35px;
        
      }
    }
  }
}
.shadowForChangeClick{
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.shadow {
  height: 900px;

  position: absolute;
  z-index: 3;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.6);
  left: 0px;
}
.popUp {
  z-index: 4;
  position: absolute;
  width: 600px;
  height: 520px;
  background: white;
  border-radius: 10px;
  right: 33%;
  padding-left: 24px;
  .popUpCloseButt {
    border: none;
    background: none;
    position: relative;
    left: 570px;
    top: 10px;
    cursor: pointer;
  }
  h3 {
    font-family: Rubik;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 46px;
    color: #063869;
  }
  h5 {
    font-family: Rubik;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 30px;
    color: #063869;
  }
  .thumbnailsContent {
    display: flex;
    flex-direction: column;
    .thumbnailsContentButtons {
      display: flex;
      justify-content: space-between;
    }
    .thumbnailsContentRow {
      display: flex;
      justify-content: space-around;
    }
  }
  .addButton {
    border: none;
    background: none;
    margin-top: 40px;
    position: relative;
    left: 446px;
  }
}
