@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: Rubik;
  src: url(../fonts/Rubik-Regular.ttf);
}
.overviewHeader {
  margin-top: 30px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 35px;
  color: #05396b;
}
.latestContent {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(56, 149, 131, 0.25);
  border-radius: 16px;
  padding: 15px 0;
}
.rightmenu {
  margin-left: 290px;
  padding: 0 50px;
  width: 100%;
  @media (max-width: 768px){
    margin-left: 160px;
    padding: 0 20px;
  }
}
.latestComponent {
  img {
    width: 100%;
    border-radius: 20px;
  }
  h4 {
    position: absolute;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #ffffff;
    top: 60px;
    left: 35px;
  }
  .latestComponentInfoBlock {
    display: flex;
    width: 265px;
    position: absolute;
    bottom: 15px;
    left: 35px;
    margin-right: 15px;
    border-top: 0.75px solid rgba($color: #ffffff, $alpha: 0.4);
    padding-top: 15px;
    img {
      width: 16px;
      height: 16px;
    }
    .latestComponentInfoBlockChild {
      display: flex;
      margin-right: 20px;
      align-content: center;
      h6 {
        margin: 0;
        padding: 0;
        font-size: 12px;
        line-height: 14px;
        display: flex;
        align-items: center;
        margin-left: 10px;
        color: #ffffff;
      }
    }
  }
}
.hostingInfo {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(55, 149, 131, 0.25);
  border-radius: 16px;
  padding: 50px;
  margin-top: 30px;
    @media (max-width: 768px) {
    padding: 20px;
  }
  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #063869;
  }
  .hostingInfoBlock {
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(56, 149, 131, 0.25);
    border-radius: 16px;
    margin-top: 42px;
    padding: 15px;
    img {
      margin-top: 18px;
      margin-bottom: 32px;
    }
    h4 {
      font-weight: bold;
      font-size: 12px;
      line-height: 14px;
      color: #379583;
      margin-bottom: 18px;
    }
    h5 {
      margin-left: 7px;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #05396b;
    }
  }
}
.lastGrafBlock {
  margin-bottom: 70px;
}
.smallOverviewBlock {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(55, 149, 131, 0.25);
  border-radius: 10px;
  margin-top: 10px;
}
.videoAnalytics {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(55, 149, 131, 0.25);
  border-radius: 16px;
  margin-top: 30px;
  padding: 50px;
  @media (max-width: 768px) {
    padding: 20px;
  }
  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #063869;
  }
}
.videoAnalyticsBlock {
  height: 200px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.25px;
  padding-top: 14px;
  color: #379583;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(55, 149, 131, 0.25);
  border-radius: 16px;
  margin-top: 33px;
  overflow: hidden;
  h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.25px;
    max-width: 115px;
    color: #379583;
    margin-top: 14px;
    margin-bottom: 30px;
    min-height: 30px;
    max-width: 110px;
  }
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-top: 15px;
    color: #063869;
    display: flex;
    align-items: flex-start;
  }
  h4 {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(92, 219, 149, 1);
    position: absolute;
    top: 103px;
    left: 75px;
    transform: rotate(-90deg);
  }
  h3 {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.25px;
    position: absolute;
    top: 178px;
    left: 195px;
    color: rgba(92, 219, 149, 1);
  }
}
.graf {
  position: absolute;
  height: 143px;
  width: calc(100% - 140px);
  border-left: 2px solid #5cdb95;
  border-bottom: 2px solid #5cdb95;
  top: 30px;
  left: 130px;
}
.visitsByCountryBlock {
  height: 200px;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.25px;
  padding-top: 14px;
  color: #379583;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(55, 149, 131, 0.25);
  border-radius: 16px;
  margin-top: 33px;
  h5 {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.25px;
    max-width: 115px;
    color: #379583;
    margin-top: 14px;
    margin-bottom: 30px;
  }
  h6 {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    margin-top: 10px;
    color: #063869;
    display: flex;
    align-items: flex-start;
  }
  h4 {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.25px;
    color: rgba(92, 219, 149, 0.5);
    position: absolute;
    top: 103px;
    left: 78px;
    transform: rotate(-90deg);
  }
  h3 {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    letter-spacing: 0.25px;
    position: absolute;
    top: 178px;
    left: 195px;
    color: rgba(92, 219, 149, 0.5);
  }
}
