
@font-face {
  font-family: Roboto;
  src: url(../fonts/Roboto-Regular.ttf);
}
@font-face {
  font-family: Rubik;
  src: url(../fonts/Rubik-Regular.ttf);
}
.leftMenu {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 0px 6px #d4d4d4;
  width: 290px;
  max-width: 290px;
  height: 100%;
  position: fixed;
  padding: 25px 0;
  padding-right: 20px;
  overflow: auto;
  @media (max-width: 768px){
    width: 160px;
  }
  .menuItem {
    padding: 16px 10px 16px 40px;
    @media (max-width: 768px){
      padding: 15px 10px;
    }
    cursor: pointer;
    margin-bottom: 1px;
    transition: all 0.1s ease-in-out;
    button {
      background: none;
      border: none;
      font-size: 16px;
      line-height: 22px;
      color: #389583;
    }
    &:hover{
      background-color: #5cdb94;
      border-radius: 0 100px 100px 0;
      button{
      color: #fff;
      }
    }
  }
  .menuItemActive {
    background: #5cdb94;
    border-radius: 0 100px 100px 0;
    button {
      color: #ffffff;
    }
  }
  .leftMenuBottomBlock {
    margin-top: auto;
    h5 {
      font-size: 14px;
      line-height: 17px;
      color: rgba(56, 149, 131, 0.8);
    }
  }
}
